<template>
  <div>
    <CalendarView
      :init-date="initDate"
      :show-event-type="true"
      :show-rentable="false"
      :schedule-events="userEventsPassedOnly"
      :schedule-dots="userScheduleDots"
      :selected-date="selectedDate"
      @select-date="selectDateFromCalendar"
      @select-event="selectEventFromCalendar"
    >
      <template #footer>
        <div class="schedule-legend d-none-md-below">
          <div class="schedule-legend-item">
            <div class="tag tag-blue mr-1">藍色</div>為已承租表演廳
          </div>
          <div class="schedule-legend-item">
            <div class="tag tag-orange mr-1">橘色</div>為已承租文化館
          </div>
        </div>
      </template>
    </CalendarView>

    <div class="schedule-panel">
      <!-- selected 日期 -->
      <div class="schedule-panel-header">
        {{ $moment(selectedDate).format("L") }}
      </div>

      <!-- 活動列表 -->
      <div class="schedule-panel-content">
        當天活動
        <ul v-if="selectedEvents.length > 0 || false">
          <li v-for="event in selectedEvents" :key="event.id">
            <CollapseItem>
              <template #title>{{ event.title }}</template>
              <template #content>
                <span class="tag tag-blue" v-if="event.siteId === 1">表演廳</span>
                <span class="tag tag-orange" v-else>文化館</span>
                <div>
                  {{ $moment(event.startDate).format("L") }}
                  <span v-if="event.endDate">至 {{ $moment(event.endDate).format('L') }}</span>
                </div>

              </template>
            </CollapseItem>
          </li>
        </ul>
        <div v-else>本日無活動</div>
      </div>
    </div>

    <Transition name="fade">
      <div
        class="schedule-bubble"
        v-if="bubbleInfo"
        v-click-outside="toggleBubble"
        :style="{
          top: `${bubblePos.y - 20}px`,
          left: `${bubblePos.x}px`,
        }"
      >
        <div class="schedule-bubble-image" v-show="!!bubbleInfo.coverImage">
          <img :src="bubbleInfo.coverImage" alt="" />
        </div>
        <div class="schedule-bubble-content">
          <div class="schedule-bubble-title">
            <a :href="bubbleInfo.eventLink" v-if="bubbleInfo.eventLink" target="_blank">{{ bubbleInfo.title }}</a>
            <template v-else>{{ bubbleInfo.title }}</template>
          </div>
          <div class="schedule-bubble-meta">
            {{ $moment(bubbleInfo.startDate).format('L') }}
            <span v-if="bubbleInfo.endDate">至 {{ $moment(bubbleInfo.endDate).format('L') }}</span>
          </div>
          <div class="schedule-bubble-meta" v-if="bubbleInfo.notification.length > 0">
            <span class="text-danger">
              <i class="icon icon-bell"></i>
              請於 {{ $moment(bubbleInfo.notification[0].expectedDate).format('L') }} 前繳清
              {{ bubbleInfo.notification[0].chargeName }}
            </span>
          </div>

        </div>
      </div>
    </Transition>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState } from 'pinia';
import { useUserEvents } from '@/store/user';
import CalendarView from '@/components/CalendarView.vue';
import CollapseItem from '@/components/CollapseItem.vue';

export default {
  components: {
    CalendarView,
    CollapseItem,
  },
  data() {
    return {
      initDate: this.todayDateObj(), // 初始日期
      currentFirstDate: this.$moment().startOf('month').toDate(), // 當前月第一日
      selectedDate: this.todayDateObj(),
      bubbleInfo: null,
      bubblePos: {
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    selectDateFromCalendar(dateObj) {
      this.selectedDate = dateObj;
    },
    selectEventFromCalendar(eventObj, windowEvent) {
      const { originalItem: event } = eventObj;
      const { pageX: x, pageY: y } = windowEvent;
      this.toggleBubble(event, { x, y });
    },
    toggleBubble(eventObj, pos) {
      this.bubbleInfo = eventObj || null;
      this.bubblePos = pos || { x: 0, y: 0 };
    },
  },
  computed: {
    selectedEvents() {
      const selectedDate = this.selectedDate.getTime();
      return _.filter(
        this.userEventsPassedOnly,
        ({ startDate, endDate }) => {
          if (endDate) return this.$moment(selectedDate).isBetween(startDate, endDate);
          return this.$moment(startDate).isSame(selectedDate, 'day');
        },
      );
    },
    userScheduleDots() {
      return _.chain(this.userEventsPassedOnly)
        .map(({ startDate, endDate, siteId }) => _.map(this.dateDurationToArray(startDate, endDate), (item) => ({ ...item, siteId })))
        .flatMap()
        .uniqWith(_.isEqual)
        .reduce((result, { startDate, siteId }) => {
          if (!result[startDate]) result[startDate] = [];
          result[startDate].push(siteId);
          return result;
        }, {})
        .toPairs()
        .map((item) => ({
          startDate: item[0],
          type: _.sortBy(item[1]),
        }))
        .value();
    },
    ...mapState(useUserEvents, ['userEventsPassedOnly']),
  },
};
</script>
